import React from 'react';
import './Nav.scss';
import { Link } from 'react-router-dom';
import { User } from '../../../Dashboard';
import { Permission } from '../Permissions/Permissions';

enum NavState {
  OPEN = 'open',
  CLOSED = 'closed'
}

const icons = {
  'lab': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M14.5405 2V4.48622C14.5405 6.23417 14.5405 7.10814 14.7545 7.94715C14.9685 8.78616 15.3879 9.55654 16.2267 11.0973L17.3633 13.1852C19.5008 17.1115 20.5696 19.0747 19.6928 20.53L19.6792 20.5522C18.7896 22 16.5264 22 12 22C7.47357 22 5.21036 22 4.3208 20.5522L4.30725 20.53C3.43045 19.0747 4.49918 17.1115 6.63666 13.1852L7.7733 11.0973C8.61209 9.55654 9.03149 8.78616 9.24548 7.94715C9.45947 7.10814 9.45947 6.23417 9.45947 4.48622V2" stroke="currentColor" strokeWidth="1.5" />
      <path d="M9 16.002L9.00868 15.9996" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 18.002L15.0087 17.9996" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2L16 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11.5563C8.5 10.4029 10.0994 11.2343 12 12.3182C14.5 13.7439 16 12.65 16.5 11.6152" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ),
  'tecnica': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M9 4.5C5.50442 5.70104 3 8.94175 3 12.7511C3 13.9579 3.25134 15.1076 3.70591 16.1534M15 4.5C18.4956 5.70104 21 8.94175 21 12.7511C21 13.7736 20.8195 14.7552 20.4879 15.6674M16.5 20.3296C15.1762 21.074 13.6393 21.5 12 21.5C10.3607 21.5 8.82378 21.074 7.5 20.3296" stroke="currentColor" strokeWidth="1.5" />
      <path d="M15 5C15 6.65685 13.6569 8 12 8C10.3431 8 9 6.65685 9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5Z" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="5" cy="19" r="3" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="19" cy="19" r="3" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  ),
  'tecnica2': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M11.1075 5.57624C11.3692 6.02707 11.5 6.25248 11.5 6.5C11.5 6.74752 11.3692 6.97293 11.1075 7.42376L9.85804 9.57624C9.59636 10.0271 9.46551 10.2525 9.25 10.3762C9.03449 10.5 8.7728 10.5 8.24943 10.5H5.75057C5.2272 10.5 4.96551 10.5 4.75 10.3762C4.53449 10.2525 4.40364 10.0271 4.14196 9.57624L2.89253 7.42376C2.63084 6.97293 2.5 6.74752 2.5 6.5C2.5 6.25248 2.63084 6.02707 2.89253 5.57624L4.14196 3.42376C4.40364 2.97293 4.53449 2.74752 4.75 2.62376C4.96551 2.5 5.2272 2.5 5.75057 2.5L8.24943 2.5C8.7728 2.5 9.03449 2.5 9.25 2.62376C9.46551 2.74752 9.59636 2.97293 9.85804 3.42376L11.1075 5.57624Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.1075 11.5762C21.3692 12.0271 21.5 12.2525 21.5 12.5C21.5 12.7475 21.3692 12.9729 21.1075 13.4238L19.858 15.5762C19.5964 16.0271 19.4655 16.2525 19.25 16.3762C19.0345 16.5 18.7728 16.5 18.2494 16.5H15.7506C15.2272 16.5 14.9655 16.5 14.75 16.3762C14.5345 16.2525 14.4036 16.0271 14.142 15.5762L12.8925 13.4238C12.6308 12.9729 12.5 12.7475 12.5 12.5C12.5 12.2525 12.6308 12.0271 12.8925 11.5762L14.142 9.42376C14.4036 8.97293 14.5345 8.74752 14.75 8.62376C14.9655 8.5 15.2272 8.5 15.7506 8.5L18.2494 8.5C18.7728 8.5 19.0345 8.5 19.25 8.62376C19.4655 8.74752 19.5964 8.97293 19.858 9.42376L21.1075 11.5762Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1075 16.5762C11.3692 17.0271 11.5 17.2525 11.5 17.5C11.5 17.7475 11.3692 17.9729 11.1075 18.4238L9.85804 20.5762C9.59636 21.0271 9.46551 21.2525 9.25 21.3762C9.03449 21.5 8.7728 21.5 8.24943 21.5H5.75057C5.2272 21.5 4.96551 21.5 4.75 21.3762C4.53449 21.2525 4.40364 21.0271 4.14196 20.5762L2.89253 18.4238C2.63084 17.9729 2.5 17.7475 2.5 17.5C2.5 17.2525 2.63084 17.0271 2.89253 16.5762L4.14196 14.4238C4.40364 13.9729 4.53449 13.7475 4.75 13.6238C4.96551 13.5 5.2272 13.5 5.75057 13.5L8.24943 13.5C8.7728 13.5 9.03449 13.5 9.25 13.6238C9.46551 13.7475 9.59636 13.9729 9.85804 14.4238L11.1075 16.5762Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  'contingut': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  ),
  'calendari': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M18 2V4M6 2V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9955 13H12.0045M11.9955 17H12.0045M15.991 13H16M8 13H8.00897M8 17H8.00897" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 8H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 8H21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  'perfil': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M7.78256 17.1112C6.68218 17.743 3.79706 19.0331 5.55429 20.6474C6.41269 21.436 7.36872 22 8.57068 22H15.4293C16.6313 22 17.5873 21.436 18.4457 20.6474C20.2029 19.0331 17.3178 17.743 16.2174 17.1112C13.6371 15.6296 10.3629 15.6296 7.78256 17.1112Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 10C15.5 11.933 13.933 13.5 12 13.5C10.067 13.5 8.5 11.933 8.5 10C8.5 8.067 10.067 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10Z" stroke="currentColor" strokeWidth="1.5" />
      <path d="M2.854 16C2.30501 14.7664 2 13.401 2 11.9646C2 6.46129 6.47715 2 12 2C17.5228 2 22 6.46129 22 11.9646C22 13.401 21.695 14.7664 21.146 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ),
  'comunicacio': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M14.6171 4.76655C15.6275 3.16588 16.1327 2.36554 16.7947 2.12444C17.2503 1.95852 17.7497 1.95852 18.2053 2.12444C18.8673 2.36554 19.3725 3.16588 20.3829 4.76655C21.5202 6.56824 22.0889 7.46908 21.9887 8.21239C21.92 8.72222 21.6634 9.18799 21.2693 9.51835C20.6947 10 19.6298 10 17.5 10C15.3702 10 14.3053 10 13.7307 9.51835C13.3366 9.18799 13.08 8.72222 13.0113 8.21239C12.9111 7.46908 13.4798 6.56824 14.6171 4.76655Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M2 6C2 4.33345 2 3.50018 2.42441 2.91603C2.56147 2.72738 2.72738 2.56147 2.91603 2.42441C3.50018 2 4.33345 2 6 2C7.66655 2 8.49982 2 9.08397 2.42441C9.27262 2.56147 9.43853 2.72738 9.57559 2.91603C10 3.50018 10 4.33345 10 6C10 7.66655 10 8.49982 9.57559 9.08397C9.43853 9.27262 9.27262 9.43853 9.08397 9.57559C8.49982 10 7.66655 10 6 10C4.33345 10 3.50018 10 2.91603 9.57559C2.72738 9.43853 2.56147 9.27262 2.42441 9.08397C2 8.49982 2 7.66655 2 6Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <circle cx="17.5" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M9.49994 14.5L2.5 21.5M2.50006 14.5L9.5 21.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  'seccions': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
      <path d="M2 11.4C2 10.2417 2.24173 10 3.4 10H20.6C21.7583 10 22 10.2417 22 11.4V12.6C22 13.7583 21.7583 14 20.6 14H3.4C2.24173 14 2 13.7583 2 12.6V11.4Z" stroke="currentColor" strokeWidth="1.5" stroke-Linecap="round" />
      <path d="M2 3.4C2 2.24173 2.24173 2 3.4 2H20.6C21.7583 2 22 2.24173 22 3.4V4.6C22 5.75827 21.7583 6 20.6 6H3.4C2.24173 6 2 5.75827 2 4.6V3.4Z" stroke="currentColor" strokeWidth="1.5" stroke-Linecap="round" />
      <path d="M2 19.4C2 18.2417 2.24173 18 3.4 18H20.6C21.7583 18 22 18.2417 22 19.4V20.6C22 21.7583 21.7583 22 20.6 22H3.4C2.24173 22 2 21.7583 2 20.6V19.4Z" stroke="currentColor" strokeWidth="1.5" stroke-Linecap="round" />
    </svg>
  )
}

export default class Nav extends React.Component<{}, {
  navState: NavState;
  active: string;
}> {
  constructor(props: any, data: any) {
    super(props);
    this.state = {
      navState: NavState.OPEN,
      active: ''
    };
  }

  static readonly data = {
    permissions: Permission.ADMIN,
    sections: [
      {
        name: 'calendari',
        displayName: 'Calendari',
        icon: icons['calendari'],
        permissions: Permission.COMUNICACIO,
      },
      {
        name: 'seccions',
        displayName: 'Seccions',
        icon: icons['seccions'],
        permissions: Permission.ADMIN,
      },
      {
        name: 'contingut',
        displayName: 'Contingut',
        icon: icons['contingut'],
        permissions: Permission.ADMIN,
        sub: [
          {
            name: 'noticia/new',
            displayName: 'Nova Notícia',
            icon: icons['lab'],
          },
          {
            name: 'noticia/edit',
            displayName: 'Editar Notícia',
            icon: icons['lab'],
          },
        ]
      },
      {
        name: 'comunicacio',
        displayName: 'Comunicació',
        icon: icons['comunicacio'],
        permissions: Permission.COMUNICACIO,
        sub: [
          {
            name: 'mail',
            displayName: 'Correu',
            icon: icons['lab'],
          }
        ]
      },
      {
        name: 'tecnica',
        displayName: 'Tècnica',
        icon: icons['tecnica2'],
        permissions: Permission.TECNICA,
        sub: [
          {
            name: 'pinyes',
            displayName: 'Pinyes',
            icon: icons['lab'],
          },
          {
            name: 'edit',
            displayName: 'Troncs',
            icon: icons['lab'],
          },
        ]
      },
      {
        name: 'me',
        displayName: 'Perfil',
        icon: icons['perfil'],
        permissions: Permission.ADMIN,
      },
      {
        name: 'perms',
        displayName: 'Permissos',
        icon: icons['lab'],
        permissions: Permission.ADMIN,
      },
    ]
  }

  componentDidMount() {
    this.setState({ active: window.location.pathname });
  }

  hasPermission(permissions: number, permission: number): boolean {
    if (!!(permissions & Permission.ADMIN)) return true;
    return !!(permissions & permission);
  }

  render(): React.ReactNode {
    return (
      <User.Consumer>
        {(context) => {
          const permissions = Permission.ADMIN; // context?.permissions
          console.log(this.hasPermission(permissions, Permission.ADMIN));
          return (
            <aside data-state={this.state.navState} className='menu'>
              <span className="toggleNav altStyle" onClick={
                (e) => {
                  if (this.state.navState === 'closed') {
                    this.setState({ navState: NavState.OPEN });
                  } else {
                    this.setState({ navState: NavState.CLOSED });
                  }
                }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              </span>
              <header>
                <Link to='/' onClick={
                  () => {
                    this.setState({ active: '/' });
                  }
                }>
                  <img src="/svg/logo3D.svg" alt="" width='80px' height='80px' />
                  <h1>Castellers de Mollet</h1>
                </Link>
              </header>
              <nav>
                <ul>
                  {
                    Nav.data.sections.map((item, index) => {
                      if (this.hasPermission(permissions, item.permissions)) {
                        return (
                          <li key={index} className={`section ${this.state.active.includes(item.name) ? 'active' : ''}`}>
                            <Link to={item.name} onClick={
                              () => {
                                this.setState({ active: item.name });
                              }
                            }>
                              <div>
                                {item.icon}
                                <span>{item.displayName}</span>
                              </div>
                              {
                                item.sub && (
                                  <button className='toggleSubmenu' onClick={
                                    (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const section = e.currentTarget.parentElement.parentElement?.querySelector('.subSections');
                                      if (section) {
                                        section.classList.toggle('open');
                                      }
                                    }
                                  }>
                                    <svg className='arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                      <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </button>
                                )
                              }
                            </Link>
                            {
                              item.sub && (
                                <ul className="subSections">
                                  {
                                    item.sub.map((sub, i) => (
                                      <li key={i} className={`subSection ${this.state.active.includes(sub.name) ? 'active' : ''}`}>
                                        <Link to={`/${item.name}/${sub.name}`} onClick={
                                          () => {
                                            this.setState({ active: `${item.name}/${sub.name}` });
                                          }
                                        }>
                                          <span>{sub.displayName}</span>
                                        </Link>
                                      </li>
                                    ))
                                  }
                                </ul>
                              )
                            }
                          </li>
                        );
                      }
                    })
                  }
                  <li className='section'>
                    <button className='tancarSessio' onClick={
                      () => {
                        const cookie = document.cookie.match(/user=(.*?)(;|$)/);
                        if (cookie) {
                          document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
                          window.location.href = '/';
                        }
                      }
                    }>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M15 17.625C14.9264 19.4769 13.3831 21.0494 11.3156 20.9988C10.8346 20.987 10.2401 20.8194 9.05112 20.484C6.18961 19.6768 3.70555 18.3203 3.10956 15.2815C3 14.723 3 14.0944 3 12.8373L3 11.1627C3 9.90561 3 9.27705 3.10956 8.71846C3.70555 5.67965 6.18961 4.32316 9.05112 3.51603C10.2401 3.18064 10.8346 3.01295 11.3156 3.00119C13.3831 2.95061 14.9264 4.52307 15 6.37501" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
                          <path d="M21 12H10M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>Sortir</span>
                      </div>
                    </button>
                  </li>
                </ul>
              </nav>
              <button className='toogleTheme' onClick={
                () => {
                  (document.querySelector('div.App') as HTMLElement).dataset.theme = (document.querySelector('div.App') as HTMLElement).dataset.theme === 'dark' ? 'light' : 'dark';
                  document.cookie = ''
                  document.cookie = `theme=${(document.querySelector('div.App') as HTMLElement).dataset.theme}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
                }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zM96 256c0-70.7 57.3-128 128-128v256C153.3 384 96 326.7 96 256zM256 416V96c70.7 0 128 57.3 128 128S326.7 416 256 416z" /></svg>
              </button>
              <section className="profileDetails">
                <div className="card">
                  <span className="avatar">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                      <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                  </span>
                  <span className="name">Aarón Sánchez Jiménez</span>
                  <span className="tag">@{context.username}</span>
                </div>
                <div className="settings"></div>
              </section>
            </aside>
          )
        }}
      </User.Consumer>
    );
  }
}