type Header = {
  title: string;
  image: string;
  description: string;
}

type New = {
  id: number;
  header: Header;
  date: string;
  body?: string;
}

export const news: New[] = [
  {
    id: 1,
    header: {
      title: 'Primera edició del premi “Treballs Recerca sobre el Món Casteller”',
      image: 'https://castellersdemollet.cat/wp-content/uploads/2022/10/Cartell-Premis-Castellers-VO.jpg',
      description: 'PlaceHolder'
    },
    date: '2023-12-09',
    body: [
      {
        type: 'paragraph',
        text: 'Ja estan obertes les inscripcions de la primera edició'
      },
      {
        type: 'paragraph',
        text: 'Els Xics de Granollers, Castellers de Caldes de Montbui, els Manyacs de Parets i els Castellers de Mollet promouen conjuntament el primer premi “Castellers V.O. de treballs de Recera”. Aquest premi te com a objectiu apropar el món casteller als joves que estudiïn batxillerat en qualsevol dels centres del Vallès Oriental.'
      },
      {
        type: 'paragraph',
        text: 'Si cliqueu aquí, podreu baixar-vos les bases del premi. https://castellersdemollet.cat/wp-content/uploads/2022/10/Bases-Premi-Castellers-VO.pdf.'
      },
      {
        type: 'paragraph',
        text: 'Les inscripcions ja estan obertes, i podeu fer-ho fins al proper 31 de març del 2024 omplint el següent formulari i enviant-lo a premicastellersvo@gmail.com.'
      }
    ].map(
      (component) => component.text
    ).join('<br><br>')
  },
  {
    id: 2,
    header: {
      title: 'Els Castellers de Mollet presentem el logo del seu 30è aniversari',
      image: 'https://castellersdemollet.cat/wp-content/uploads/2022/03/Logo30aniversari.jpg',
      description: 'PlaceHolder'
    },
    date: '2022-03-10',
    body: [
      {
        type: 'paragraph',
        text: 'Dissabte 12 de febrer a La Marineta vam portar a terme la presentació del logo del 30è aniversari, així com el programa d’activitats que durem a terme aquest 2022 a casa.'
      },
      {
        type: 'paragraph',
        text: 'D’entre les vuit imatges presentades, la realitzada per l’egarenc Jordi Dobón en va ser l’escollida i serà el disseny que acompanyarà la colla al llarg de l’any, tant a les xarxes com en marxandatge, entre d’altres.'
      },
      {
        type: 'paragraph',
        text: 'Dobón, qui és dissenyador gràfic i ja va ser el creador del logotip dels 40 anys dels Castellers de Terrassa, es mostrava molt satisfet per haver- ne estat el més votat del jurat: “No només pel premi, sinó pel prestigi, perquè la colla portarà un disseny meu”.'
      },
      {
        type: 'paragraph',
        text: 'https://castellersdemollet.cat/wp-content/uploads/2022/03/20220212_190701.jpg'
      },
      {
        type: 'paragraph',
        text: 'Aquest logo serà la imatge corporativa de tots els actes que es duran a terme durant aquest any tan especial per a la colla. Aquest logo ja es pot veure a la nostra web i a les xarxes socials.'
      },
    ].map(
      (component) => component.text
    ).join('<br><br>')
  },
  {
    id: 3,
    header: {
      title: 'Nova junta i tècnica per la temporada 2020',
      image: 'https://castellersdemollet.cat/wp-content/uploads/2022/01/Cartell-Sant-Vicenc-2022.jpg',
      description: 'PlaceHolder'
    },
    date: '2019-12-18',
    body: [
      {
        type: 'paragraph',
        text: 'En Ciscu Carvajal com a President i en Javi Carvajal com a Cap de Colla, van ser escollits a l’assemblea de Castellers de Mollet que va tenir lloc dissabte 15 de desembre les 19:30h. S’arribava amb una única candidatura de Junta i una única candidatura de Tècnica, que van ser aprovades per una àmplia majoria:'
      },
      {
        type: 'paragraph',
        text: 'Junta<br>- Vots a favor: 83<br>- Vots en contra: 0<br>- Vots en blanc: 0'
      },
      {
        type: 'paragraph',
        text: 'Tècnica<br>- Vots a favor: 82<br>- Vots en contra: 1<br>- Vots en blanc: 0'
      },
      {
        type: 'paragraph',
        text: 'Amb aquest resultat, la Junta està composada per:'
      },
      {
        type: 'paragraph',
        text: '- Presidència: Ciscu Carvajal<br>- Vicepresidència: Rosalia Areñas<br>- Secretaria: Jaume Vergés<br>- - Adjunta de Secretaria: Rosalia Areñas<br>- Tresoreria: Ferran Marcos<br>- - Adjunt Tresoreria: Josep M. Corretger<br>- Àlex Tres<br>- - Equip Sanitat: Marina Vall, Toni Ramoneda i Maria Domènech<br>- Vocal d\'imatge i comunicació: Samuel Ferrando<br>- Equip Comunicació: Màrius Alarcón, Enna Ansó, Rafael Ferran "Wally", Joanma Gòmez i Antonio López<br>- Vocal Material: Joanma Gómez i Mari Reca<br>- Vocal Social: Toni Ramoneda<br>- Equip Social: Toni Ciudad<br>- Relacions Públiques: Roger Parera'
      },
    ].map(
      (component) => component.text
    ).join('<br><br>')
  }
]