import React from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './views/Dashboard/Dashboard.scss';
import Loading from './components/Loading/Loading';
import Nav from './views/Dashboard/Nav2/Nav';
import CartellV2 from './views/Dashboard/Comunicació/CartellV2/CartellV2';
const GoogleLogin = React.lazy(() => import('./components/Auth/Auth'));
const Tecnica = React.lazy(() => import('./views/Dashboard/Tecnica/Tecnica2'));
const Permissions = React.lazy(() => import('./views/Dashboard/Permissions/Permissions'));
const Comunicació = React.lazy(() => import('./views/Dashboard/Comunicació/Comunicació'));
const EditorSeccions = React.lazy(() => import('./views/Dashboard/EditorSeccions/EditorSeccions'));
const EditorCalendari = React.lazy(() => import('./views/Dashboard/EditorCalendari/EditorCalendari'));
const EditorContingutHome = React.lazy(() => import('./views/Dashboard/EditorContingut/EditorContingutHome.routes'));
const Perfil = React.lazy(() => import('./views/Dashboard/Perfil/Perfil'));

export const User = React.createContext({} as UserInterface);
export interface UserInterface {
	id: string;
	email: string;
	username: string;
	fullName: string;
	permissions: number;
}

export default class Dashboard extends React.Component {
	render(): React.ReactNode {
		const cookie = document.cookie.split('; ').find(row => row.startsWith('user='))?.split('=')[1];
		if (!cookie) {
			return <GoogleLogin />;
		}
		const user = JSON.parse(cookie) as UserInterface;
		return (
			<Router>
				<User.Provider value={user}>
					<Nav />
					<main className='dash'>
						<Routes>
							<Route path='/' element={
								<React.Suspense fallback={<Loading />}>
									<div className='home'>
										<ul className='seccions'>
											<li>
												<Link to='/calendari'>
													<h2>Calendari</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='WIP'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M13 11L18 6" stroke="currentColor" strokeWidth="1.5" />
															<path d="M19 7L17 5L19.5 3.5L20.5 4.5L19 7Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
															<path d="M4.02513 8.97487C3.01416 7.96391 2.75095 6.48836 3.23548 5.23548L4.65748 6.65748H6.65748V4.65748L5.23548 3.23548C6.48836 2.75095 7.96391 3.01416 8.97487 4.02513C9.98621 5.03647 10.2493 6.51274 9.76398 7.76593L16.2341 14.236C17.4873 13.7507 18.9635 14.0138 19.9749 15.0251C20.9858 16.0361 21.2491 17.5116 20.7645 18.7645L19.3425 17.3425L17.3425 17.3425V19.3425L18.7645 20.7645C17.5116 21.2491 16.0361 20.9858 15.0251 19.9749C14.0145 18.9643 13.7511 17.4895 14.2349 16.2369L7.76312 9.76507C6.51053 10.2489 5.03571 9.98546 4.02513 8.97487Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
															<path d="M12.203 14.5L6.59897 20.1041C6.07115 20.6319 5.2154 20.6319 4.68758 20.1041L3.89586 19.3123C3.36805 18.7845 3.36805 17.9288 3.89586 17.401L9.49994 11.7969" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Noticies</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='AVAILABLE'></span>
												</Link>
											</li>
											<li>
												<Link to='/seccions'>
													<h2>Seccions</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='INACTIVE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M5.25 5L19.25 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<path d="M22.25 12C22.25 6.47715 17.7728 2 12.25 2C6.72715 2 2.25 6.47715 2.25 12C2.25 17.5228 6.72715 22 12.25 22C17.7728 22 22.25 17.5228 22.25 12Z" stroke="currentColor" strokeWidth="1.5" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
											<li>
												<Link to='/contingut'>
													<h2>Contingut</h2>
													<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
													<span className="status" datatype='UNAVAILABLE'>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
															<path d="M12 16.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
															<path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
													</span>
												</Link>
											</li>
										</ul>
									</div>
								</React.Suspense>
							} />
							<Route path='/contingut/*' element={
								<React.Suspense fallback={<Loading />}>
									<EditorContingutHome />
								</React.Suspense>
							} />
							<Route path='/perms' element={
								<React.Suspense fallback={<Loading />}>
									<Permissions />
								</React.Suspense>
							} />
							<Route path='/comunicacio' element={
								<React.Suspense fallback={<Loading />}>
									<Comunicació />
								</React.Suspense>
							} />
							<Route path='/comunicacio/cartell' element={
								<React.Suspense fallback={<Loading />}>
									<CartellV2 />
								</React.Suspense>
							} />
							<Route path='/tecnica' element={
								<React.Suspense fallback={<Loading />}>
									<Tecnica />
								</React.Suspense>
							} />
							<Route path='/calendari' element={
								<React.Suspense fallback={<Loading />}>
									<EditorCalendari />
								</React.Suspense>
							} />
							<Route path='/seccions' element={
								<React.Suspense fallback={<Loading />}>
									<EditorSeccions />
								</React.Suspense>
							} />
							<Route path='/me' element={
								<React.Suspense fallback={<Loading />}>
									<Perfil />
								</React.Suspense>
							} />
						</Routes>
					</main>
				</User.Provider >
			</Router>
		);
	}
};