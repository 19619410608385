import React from "react";
import { Link, Route, Routes } from 'react-router-dom';
import Loading from "../../components/Loading/Loading";
import Noticia from "./Noticia/Noticia";
import { news } from "./news";
import './Noticies.scss';

export default class Noticies extends React.Component<{}, { id: number; data: any }> {
  constructor(props: any) {
    super(props);
    const path = window.location.pathname.match(/\d+/);
    this.state = {
      id: path ? Number(path) : 0,
      data: null
    };
  }

  componentDidMount() {
    if (this.state.data !== null) return;
    this.fetchData();
  }

  fetchData = async (id?: string) => {
    if (this.state.data !== null) return;
    try {
      const response = await fetch(`https://api.castellersdemollet.cat/noticies`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        data: data
      });
    } catch (error: any) {
      console.error('Fetch error:', error);
    }
  };

  parseData = (data: any) => {
    return {
      banner: data.banner,
      body: data.body,
      categoria: data.categoria,
      data_pub: data.data_pub,
      date: data.date,
      imatges: data.imatges.split('-'),
    }
  };

  render(): React.ReactNode {
    return (
      <main className='noticies'>
        <Routes>
          <Route path='/' element={
            <React.Suspense fallback={<Loading />}>
              <h1>Noticies</h1>
              <ul>
                {
                  this.state.data?.map((n: any) => (
                    <li key={n.id} className="noticia">
                      <Link to={`/noticies/${n.id}`} onClick={
                        () => {
                          this.setState({ id: n.id })
                          window.scrollTo(0, 0)
                        }
                      }>
                        <Noticia id={n.id} data={n} preview />
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </React.Suspense>
          } />
          {
            this.state.id > 0 &&
            <Route path='/*' element={
              <React.Suspense fallback={<Loading />}>
                <Noticia />
                <aside>
                  <h2>Altres Noticies</h2>
                  <ul>
                    {
                      new Array(10).fill(0).map((_, i) => i + 1).filter((i) => i !== this.state.id).map((i) => (
                        <li key={i}>
                          <Link to={`/noticies/${i}`} onClick={
                            () => {
                              this.setState({ id: i })
                              window.scrollTo(0, 0)
                            }
                          }>
                            {
                              news.find((n) => n.id === i)?.header.title ?? 'Undefined'
                            }
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </aside>
              </React.Suspense>
            } />
          }
        </Routes>
      </main>
    );
  }
}