import React, { useState } from 'react';

type ImatgeAmpliableProps = {
  src: string;
  alt?: string;
  loading?: 'lazy' | 'eager';
  className?: string;
};

export const ImatgeAmpliable: React.FC<ImatgeAmpliableProps> = ({ src, alt, className, loading }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  return (
    <>
      <img src={src} alt={alt} className={`ampliable${` ${className}`}`} onClick={
        (e) => {
          setIsFullscreen(!isFullscreen);
          if (isFullscreen === false) {
            const img = e.target as HTMLElement;
            const newImg = img.cloneNode() as HTMLElement;
            newImg.style.position = 'fixed';
            newImg.style.top = '0';
            newImg.style.left = '0';
            newImg.style.zIndex = '1000';
            newImg.style.width = '100vw';
            newImg.style.height = '100vh';
            newImg.style.objectFit = 'contain';
            newImg.style.cursor = 'zoom-out';
            newImg.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
            newImg.style.padding = '2vw';
            newImg.onclick = () => {
              newImg.remove();
              setIsFullscreen(false);
            };
            const body = document.querySelector('body') as HTMLElement;
            body.appendChild(newImg);
          }
        }
      } style={{ cursor: 'zoom-in' }} loading={loading} onMouseOver={
        (e) => {
          const img = e.target as HTMLElement;
          img.style.outline = '2px solid #333';
          img.style.outlineOffset = '4px';
        }
      } onMouseOut={
        (e) => {
          const img = e.target as HTMLElement;
          img.style.outline = 'none';
        }
      } />
    </>
  );
};

export default ImatgeAmpliable;